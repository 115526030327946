@font-face {
  font-family: "A Goblin Appears!";
  src: url("../../fonts/A-Goblin-Appears.woff2") format("woff2"),
    url("../../fonts/A-Goblin-Appears.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

main * {
  margin: 0px;
  color: white;
}
main {
  font-size: 150%;
  position: relative;
}

.introduction * {
  background-color: transparent;
  text-shadow: 1px 1px black;
}

.introduction {
  width: 99%;
  margin: auto;
  position: relative;
  font-size: 200%;
  text-align: center;
  font-family: "A Goblin Appears!";
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
  height: 90vh;
  background-position: 20% 60%;
  background-size: 420%;
}
.introduction p {
  margin-top: 2rem;
  text-align: center;
  line-height: 2;
}

.hero {
  border-radius: 50%;
  width: 20%;
  height: 20%;
}

.work {
  text-align: justify;
  margin-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
}

.work h2 {
  display: inline;
  border-bottom: 5px solid grey;
}

.work p {
  margin-top: 1rem;
  line-height: 1.5;
}

.seework {
  display: block;
  margin: auto;
  margin-top: 1.2rem;
  font-size: 1rem;
  color: black;
  border: 0px;
  border-radius: 0.5rem;
  padding: 10px 12px;
}

.seework:hover {
  cursor: pointer;
}

@media screen and (min-width: 850px) {
  .introduction {
    height: 40rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    font-size: 1.8rem;
  }

  .work {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }

  .work h2 {
    font-size: 2rem;
    display: block;
    text-align: left;
    margin: auto;
  }

  .work p {
    font-size: 1rem;
    text-align: left;
  }

  .seework {
    font-size: 1rem;
    height: max-content;
    width: max-content;
    padding: 10px;
  }
}
