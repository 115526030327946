.projects {
  padding-top: 4rem;
  text-align: center;
  background-color: black;
  color: white;
  position: relative;
}

.projects * {
  margin: 0px;
}

.footer-header {
  background-size: 100%;
  background-position: no-repeat center center fixed;
  position: relative;
  padding-top: 2rem;
  width: 90%;
  text-align: center;
  margin: auto;
}

.footer-header p {
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.projects h1,
.projects p {
  font-size: 2rem;
}

.tech-stack-section {
  border-style: solid;
  padding: 2rem;
  margin: auto;
  margin-bottom: 4rem;
  width: 70%;
}
.tech-stack-section h2 {
  margin-bottom: 2rem;
  font-size: 1.3rem;
}

.tech-stack-section img {
  height: 150px;
  width: 150px;
  object-fit: contain;
  margin-left: 2rem;
}

.projects-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  position: relative;
  overflow: hidden;
}
.projects-container * {
  flex: 1 13rem;
}

.project-section {
  position: relative;
  color: #3061e3;
  background-position: top center;
  background-color: rgba (0, 0, 0, 0.5);
  width: 90%;
  margin: auto;
}

.project-container h2 {
  margin: 0px;
}

.project-section * {
  background-color: transparent;
}

.project-section p {
  margin-bottom: 1rem;
}

.project-section img {
  position: static;
  z-index: 1;
  width: 100%;
  max-height: 40rem;
  object-fit: cover;
  margin-bottom: 1rem;
}

.projects-container h4 {
  background-color: transparent;
  /* text-shadow: 1px 1px black; */
  color: #3061e3;
  position: relative;
  font-size: 3rem;
  text-align: left;
  margin-bottom: 1rem;
}

.projects-container p {
  background-color: transparent;
  position: relative;
  font-size: 1rem;
  z-index: 2;
  text-align: left;
}

.techstack {
  text-align: left;
  flex: 1;
}
.techstack h2,
.project-section h2 {
  z-index: 2;
  opacity: 0.5;
  font-size: 1rem;
  text-align: left;
  background-color: transparent;
  margin-bottom: 0.5rem;
}

.techstack p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.techstack a {
  color: #f1b000;
  text-decoration: none;
  background-color: transparent;
  font-weight: bold;
}

.techstack a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 850px) {
  .project-section {
    margin: auto;
    width: 80%;
  }
  .project-section img {
    left: 0;
    margin: auto;
    margin-bottom: 2rem;
  }

  .techstack {
    text-align: left;
  }
}
