.contact-container {
  position: relative;
  width: 90%;
  margin: auto;
}

.contact-container h1 {
  font-size: 2.6rem;
  text-align: center;
  margin-top: 0px;
  padding-top: 7rem;
}

.professional-box a {
  font-size: 1.5rem;
  color: #3061e3;
}

.social-links {
  text-align: left;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  padding: 0px;
  padding-bottom: 2rem;

  margin: 0px;
}

.socials-container h2 {
  margin-top: 5rem;
  font-size: 1.2rem;
}

.social-links li {
  text-align: left;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social-links a {
  font-size: 1rem;
  margin: 10px;
  text-underline-offset: 5px;
}

#github {
  background-color: white;
  border-radius: 11rem;
}

.social-links img {
  height: 20px;
  width: 20px;
  margin-right: 0.5rem;
}

@media screen and (min-width: 600px) {
  .contact-container {
    width: 50%;
  }

  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  .professional-box a {
    display: block;
    text-align: center;
  }

  .social-links {
    align-items: center;
  }
}
