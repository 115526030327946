.post-container {
  margin: auto;
  width: 90%;
}

.article-image-container {
  max-width: 100%;
  height: 40rem;
  margin: auto;
}

.title h1 {
  font-size: 2.7rem;
  text-align: center;
  padding-bottom: 2rem;
  padding-top: 7rem;
  margin: 0px;

  border-bottom: 1px solid grey;
}

.article-image-container img {
  display: block;
  margin: auto;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: 50%;
}

.author-date img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.author {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 1rem;
}

.date {
  text-align: left;
  font-size: 0.8rem;
}

.date p {
  text-align: left;
}

.author img {
  max-width: 100%;
}

.content {
  width: 93%;
  margin: auto;
  font-size: 1.7rem;
  line-height: 2;
}

.content::first-letter {
  font-size: 5rem;
  font-weight: bold;
  float: left;
  line-height: 1;
}

.article-bold-text {
  text-align: left;
  font-size: 2.2rem;
  font-weight: bold;
  margin: 1rem 0rem;
  display: block;
}

@media screen and (min-width: 850px) {
  .post-container {
    width: 50%;
  }
  .content {
    width: 50%;
    text-align: left;
    line-height: 2.2;
    font-size: 1rem;
  }

  .article-bold-text {
    font-size: 2rem;
  }
}
